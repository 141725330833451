<script>
import IconCategory from '../../../components/IconCategory.vue';
import CardListTemplate from '../../../components/Lists/CardListTemplate.vue';
import AnyPageTemplate from '../../../components/PageTemplates/AnyPage.vue';
import { RST_REF_CATEGORY, SEASON_COMPETITION_TYPE_CATEGORY_LEVEL, COMPETITOR, PEOPLE, RST_COMPETITOR } from '../../../LocalDB';
import CompetitionsConfigurationHelper from '../../../services/helpers/competitionsConfigurationHelper';
import LicenseeEditDialog from '../../../components/Dialogs/LicenseeEditDialog.vue';
import IndividualCompetitorEditDialog from '../../../components/Dialogs/IndividualCompetitorEditDialog.vue';
import TeamCompetitorEditDialog from '../../../components/Dialogs/TeamCompetitorEditDialog.vue';
import PeopleEditDialog from '../../../components/Dialogs/PeopleEditDialog.vue';
import FederalNumber from '../../../components/Panels/FederalNumber.vue';
import NavigateBackIcon from '../../../components/Icons/NavigateBackIcon.vue';

export default{
    components:{ AnyPageTemplate, IconCategory, CardListTemplate, LicenseeEditDialog, IndividualCompetitorEditDialog, TeamCompetitorEditDialog, PeopleEditDialog, FederalNumber, NavigateBackIcon},
    data(){
        return {
            competition_id: '',
            level_code: '',
            competition: { name: '' },
            categories: [],
            activeCategoryTabIndex: 0,
            displayLicenseeDialog: false,
            editingPilot: {
                federal_number: '',
                name: '',
                year: 0
            },
            editingTeam: {
                minMemberCount: 2,
                maxMemberCount: 2
            },
            displayCompetitorEditDialog: false,
            competitorEditDialogForCreationMode: false,
            displayCompetitorEditDialogTitle: '',
            displayCompetitorEditDialogSuggestions: [],
            canOpenSelections: false,
            canCloseSelections: false,
            canOpenRegistrations: false,
            canCloseRegistrations: false,
            canAddSelection: false,
            canAddSubscription: false,
        };
    },
    computed:{
        activeCategory(){
            var index = (this.activeCategoryTabIndex >= 0 ? this.activeCategoryTabIndex : 0);
            var ret = (this.categories != null && this.categories.length > 0) ? this.categories[index] : { isInvididual: true, code: '' };
            return ret;
        },
        nbMinFollowers(){
            return this.activeCategory.isIndividual ? 0 : (this.activeCategory.isPair ? 1 : 2);
        },
    },
    methods:{
        async refresh(){
            await this.$showRefresher();
            this.competition = await CompetitionsConfigurationHelper.get(this.competition_id);
            await CompetitionsConfigurationHelper.refreshLevelCompetitors(this.competition_id, this.level_code);
            const allComps = RST_COMPETITOR.query().where(c => c.competition_id == this.competition_id).where(c => c.level == this.level_code).orderBy(k => (k.isSelected ? 'a' : 'b') + String(k.subscriptionOrder).padStart(5,0)).get();
            console.log('allComps', allComps);
            const comp_categs = RST_REF_CATEGORY.query().where(c => c.competition_id == this.competition_id).where(c => c.level == this.level_code).get();
            console.log('compcategs', comp_categs);
            this.categories = SEASON_COMPETITION_TYPE_CATEGORY_LEVEL
                .query()
                .where(c => c.competition_type == this.competition.competition_type)
                .where(c => c.level == this.level_code)
                .where(c => c.year == this.competition.year)
                .get()
                .map(c => {
                    var comp_categ = comp_categs.find(cc => cc.category == c.category);
                    var comps = allComps.filter(comp => comp.category == c.category);
                    console.log('comps', comp_categ, comps);
                    var ret = {...c.CATEGORY, ...comp_categ, competitors: comps};
                    return ret;
                });
            console.log('categories', this.categories);
            if(this.competition.registrationMode == 'C' || this.competition.registrationMode == 'S')
            {
                this.canOpenSelections = this.competition.isLockedForSelection == true;
                this.canCloseSelections = this.competition.isLockedForSelection == false;
            } else {
                this.canOpenSelections = false;
                this.canCloseSelections = false;
            }
            if(this.competition.registrationMode == 'A' || this.competition.registrationMode == 'C' || this.competition.registrationMode == 'R'
              //|| (this.competition.registrationMode == 'N' && this.competition.)
              ){
                this.canOpenRegistrations = this.competition.isLockedForRegistration == true;
                this.canCloseRegistrations = this.competition.isLockedForRegistration == false;
            } else {
                this.canOpenRegistrations = false;
                this.canCloseRegistrations = false;
            }
            if(this.competition.registrationMode == 'A' || this.competition.registrationMode == 'N' || this.isLockedForSelection == false)
                this.canAddSubscription = true;
/*            if(this.competition.registrationMode == 'C' || this.competition.registrationMode == 'S')
                return this.competition.isLockedForSelection == false;
            if(this.competition.registrationMode == 'C' || this.competition.registrationMode == 'R')
                return this.competition.isLockedForRegistration == false;*/
            this.$hideRefresher();
        },
        async refreshCurrentTab(){
            await this.$showRefresher();
            var index = (this.activeCategoryTabIndex >= 0 ? this.activeCategoryTabIndex : 0);
            var curCateg = this.categories[index];
            var comps = RST_COMPETITOR.query().where(c => c.competition_id == this.competition_id).where(c => c.level == this.level_code).where(c => c.category == curCateg.code).orderBy('subscription_order').get();
            curCateg.competitors = comps;
            this.categories[index] = curCateg;
            this.$hideRefresher();
        },
        create(){
            var curCategory = this.activeCategory;
            console.log('create curCategory', curCategory);
            if(curCategory.isIndividual){
                this.editingPilot = {
                    federal_number: '',
                    name: '',
                    year: 0
                };
            } else {
                this.editingTeam.minMemberCount = this.activeCategory.isPair ? 2 : 3;
                this.editingTeam.maxMemberCount = this.activeCategory.isPair ? 2 : 6;
            }
            this.displayCompetitorEditDialogTitle = this.$tc("pages.competitors.edit-dialog.add-title-byMinNumberOfFollowers", this.nbMinFollowers);
            this.competitorEditDialogForCreationMode = true;
            this.displayCompetitorEditDialog = true;
        },
        getIndividualCompetitorsForSearch(search){
            if(search.length < 2) return;
            search = search.toLowerCase();
            const curCategory = this.activeCategory;
            var alreadySetCompetitorsPeopleIds = RST_COMPETITOR.query()
                .where('competition_id', this.competition_id)
                .where('category', curCategory.code)
                .where(c => c.name.toLowerCase().includes(search))
                .get()
                .map(c => c.people_id);

            var suggestions = COMPETITOR.query()
                .where('competition_type', this.competition.competition_type)
                .where('category', curCategory.code)
                .where(c => c.name.toLowerCase().includes(search))
                .where(c => !alreadySetCompetitorsPeopleIds.includes(c.people_id))
                .get()
                .map(item => {
                    alreadySetCompetitorsPeopleIds.push(item.people_id);
                    var mapped = item;
                    mapped.priority = item.category == curCategory.code ? 1 : 3;
                    mapped.priorityMatch = `#${mapped.priority}#`;
                    mapped.matches = item.category == curCategory.code ? '**' : '*';
                    mapped.forSuggestion = `${mapped.priorityMatch} ${item.name} ` + ((item.isFederal == true) ?  ' - ' + item.PEOPLE.federal_number : '');
                    return mapped;
                }).concat(
                    COMPETITOR.query()
                        .where('competition_type', this.competition.competition_type)
                        .where(c => c.isIndividual)
                        .where(c => c.category != curCategory.code)
                        .where(c => c.name.toLowerCase().includes(search))
                        .where(c => !alreadySetCompetitorsPeopleIds.includes(c.people_id))
                        .get()
                        .map(item => {
                            alreadySetCompetitorsPeopleIds.push(item.people_id);
                            var mapped = item;
                            mapped.priority = 3;
                            mapped.priorityMatch = `#${mapped.priority}#`;
                            mapped.matches = '*';
                            mapped.forSuggestion = `${mapped.priorityMatch} ${item.name} ` + ((item.isFederal == true) ?  ' - ' + item.PEOPLE.federal_number : '');
                            return mapped;
                        })
                ).concat(
                    PEOPLE.query()
                        .where(p => p.name.toLowerCase().includes(search))
                        .where(p => !alreadySetCompetitorsPeopleIds.includes(p.id))
                        .get()
                        .map(people => {
                            var priority = 5;
                            var priorityMatch = `#${priority}#`;
                            var mapped = {
                                  id: null,
                                  name: people.name,
                                  shortname: people.shortname,
                                  federal_number: people.federalNumber,
                                  firstname: people.firstname,
                                  lastname: people.lastname,
                                  sexe: people.sexe,
                                  people_id: people.id,
                                  isIndividual: true,
                                  PEOPLE: people,
                                  priority: priority,
                                  priorityMatch: priorityMatch,
                                  matches: '',
                                  forSuggestion: `${priorityMatch} ${people.name} ` + ((people.isFederal == true) ? ' - ' + people.federal_number : ''),
                              };
                              return mapped;
                        })
                );
            suggestions.sort((a,b) => { var eqdif = a.priority - b.priority; return eqdif == 0 ? a.name.localeCompare(b.name) : eqdif} );
            this.displayCompetitorEditDialogSuggestions = suggestions;
        },
        getTeamCompetitorsForSearch(search){
            if(search.length < 2) return;
            search = search.toLowerCase();
            const curCategory = this.activeCategory;
            var alreadySetCompetitorsIds = RST_COMPETITOR.query()
                .where('competition_id', this.competition_id)
                .where('category', curCategory.code)
                .where(c => c.name.toLowerCase().includes(search))
                .get()
                .map(c => c.competitor_id);

            var suggestions = COMPETITOR.query()
                .where('competition_type', this.competition.competition_type)
                .where('category', curCategory.code)
                .where(c => c.name.toLowerCase().includes(search))
                .where(c => !alreadySetCompetitorsIds.includes(c.competitor_id))
                .orderBy('name')
                .get()
                .map(item => {
                    alreadySetCompetitorsIds.push(item.competitor_id);
                    var mapped = item;
                    mapped.priority = 1;
                    mapped.priorityMatch = `#${mapped.priority}#`;
                    mapped.matches = '**';
                    mapped.forSuggestion = `${mapped.priorityMatch} ${item.name} `;
                    return mapped;
                });
            this.displayCompetitorEditDialogSuggestions = suggestions;
        },
        async navigateBack_click(){
            await this.$showLoader();
            this.$router.push({name: 'compet-admin-config', params: { id: this.competition_id } })
        },
        async changeSelectionState(openSelection){
            await this.$showSaver();
            await CompetitionsConfigurationHelper.changeLockForSelectionAsync(this.competition_id, !openSelection)
            this.canOpenSelections = !openSelection;
            this.canCloseSelections = openSelection;
            this.$hideSaver();
        },
        async changeRegistrationState(openRegistration){
            await this.$showSaver();
            await CompetitionsConfigurationHelper.changeLockForRegistrationAsync(this.competition_id, !openRegistration)
            this.$hideSaver();
        },
    },
    async mounted(){
        this.competition_id = this.$route.params.id;
        this.level_code = this.$route.params.level;
        await this.refresh();
        this.$hideLoader();
    },
    watch:{
        '$route.params': {
        handler: function(pmts){
            this.competition_id = pmts.id;
            this.level_code = pmts.level;
            this.refresh();
        },
        deep: true,
        immediate: true
      },
    }
}
</script>

<template>
    <any-page-template>
        <template #main-title-caption>{{ competition.name }}</template>
        <template #secondary-title-caption><navigate-back-icon @click="navigateBack_click" /> {{ $t('pages.competition-admin.competitors.title') }} - {{ level_code }}</template>
    
        <b-button v-if="canOpenSelections" @click="changeSelectionState(true)">Enregistrement des qualifiés</b-button>
        <b-button v-if="canCloseSelections" @click="changeSelectionState(false)">Clore les qualifications</b-button>
        <b-button v-if="canOpenRegistrations" @click="changeRegistrationState(true)">Ouvrir les inscriptions</b-button>
        <b-button v-if="canCloseRegistrations" @click="changeRegistrationState(false)">Clore les inscriptions</b-button>

        <b-tabs v-model="activeCategoryTabIndex">
            <b-tab v-for="category in categories" :key="category.category" lazy :title="category.name">
                <template #title>
                    <b-badge class="count" v-if="category.subscribers_count > 0" pill :variant="category.effective_competitors_count == 0 ? 'danger' : (category.effective_competitors_count < category.subscribers_count ? 'warning' : 'success')">{{ category.effective_competitors_count }}</b-badge>
                    <icon-category variant="dark" size="sm" v-model="category.code"/>
                </template>
                <p class="category-tab-content-title">{{ category.name }}</p>
                <card-list-template>
                    <template #header-commands-left>
                        <b-button size="sm" @click="create" v-if="canAddCompetitor">{{ $tc('pages.competition-admin.competitors.add-byMinNumberOfFollowers', nbMinFollowers) }}</b-button>
                    </template>
                    <template #header-commands-right>
                        <b-button size="sm" @click="refresh">{{ $t('pages.competition-admin.competitors.refresh') }}</b-button>
                    </template>
                    
                    <b-card v-for="competitor in category.competitors" :key="competitor.id" :class="{'open': competitor.isOpen}">
                        <div style="display:flex; justify-content: space-between;">
                            <div v-if="competitor.CATEGORY.isIndividual">
                                <b>{{ competitor.name }}</b>&nbsp;<federal-number :is-federal="competition.isFederal" v-model="competitor.LICENSEE" force-edit @edit="edit_licensee(competitor.PEOPLE)"></federal-number>
                            </div>
                            <div>
                                <b-button size="sm" @click="remove(competitor.id)" variant="danger">Suppr.</b-button>
                            </div>
                        </div>
                        <div>
                            <b-badge class="qualified" v-if="competitor.isQualified">Qualifié</b-badge>
                            <b-badge class="withdrawn" v-if="competitor.isWithdrawn">Withdrawn</b-badge>
                            <b-badge class="open" v-if="competitor.isOpen">"Open"</b-badge>
                            <b-badge class="forfeited" v-if="competitor.isForfeited">Forfait</b-badge>
                            <b-badge class="absent" v-if="competitor.isAbsent">Absent</b-badge>
                        </div>
                        <div v-if="!competitor.CATEGORY.isIndividual" style="display:flex; justify-content: flex-start;">
                            <div v-for="member in competitor.members" :key="member.id">
                                <span>{{ member.firstname }} {{ member.lastname }}</span>&nbsp;<federal-number :is-federal="competition.isFederal" v-model="member.LICENSEE" force-edit @edit="edit_licensee(member)"></federal-number>
                            </div>
                        </div>
                    </b-card>
                </card-list-template>
            </b-tab>
        </b-tabs>

        <template #dialogs-placeholder>
            <licensee-edit-dialog
                v-model="displayLicenseeDialog"
                :federalNumber = 'editingPilot.federal_number'
                :year = 'editingPilot.year'
                :peopleName = editingPilot.name
            ></licensee-edit-dialog>

            <individual-competitor-edit-dialog
                v-if="activeCategory.isIndividual"
                v-model="displayCompetitorEditDialog"
                :competition_id="competition_id"
                :level="level_code"
                :category="activeCategory.code"
                :creationMode="competitorEditDialogForCreationMode"
                :search_suggestions="displayCompetitorEditDialogSuggestions"
                @search="getIndividualCompetitorsForSearch"
                @competitorCreated="refreshCurrentTab"
                @competitorModified="refreshCurrentTab"
            >

            </individual-competitor-edit-dialog>

            <team-competitor-edit-dialog
                v-if="!activeCategory.isIndividual"
                v-model="displayCompetitorEditDialog"
                :competition_id="competition_id"
                :level="level_code"
                :category="activeCategory.code"
                :creationMode="competitorEditDialogForCreationMode"
                :minMemberCount="editingTeam.minMemberCount"
                :maxMemberCount="editingTeam.maxMemberCount"
                :search_suggestions="displayCompetitorEditDialogSuggestions"
                @search="getTeamCompetitorsForSearch"
                @competitorCreated="refreshCurrentTab"
                @competitorModified="refreshCurrentTab"
            >
            </team-competitor-edit-dialog>

            <people-edit-dialog></people-edit-dialog>
        </template>
    </any-page-template>
</template>

<style scoped>
    .category-tab-content-title{ font-weight: bold;}
</style>